import React, { useRef } from 'react';
import { Slide } from 'pure-react-carousel';
import Image from '../General/Image';

const LazySlide = ({ index, src, current, title, desc, onButtonClick, buttonText, height, blur, showDots, currentSlideIndex, ...props }) => {
    const wasVisible = useRef(false);
    const isVisible = current === index;

    if (isVisible && !wasVisible.current) {
        wasVisible.current = true;
    }

    return (
        <Slide index={index} className="relative w-full h-full">
            {wasVisible.current && (
                <div className="relative w-full h-full">
                    <Image
                        alt="Properties for Sale/Rent in Toronto and Mississauga on Rasti.ca - Bayshire Team Real Estate"
                        src={src}
                        className={`absolute inset-0 w-full h-full object-cover ${blur ? 'blur-image' : ''}`}
                        {...props}
                    />
                    <div className="absolute inset-0 bg-opacity-40 bg-black">
                        <div className="hidden md:flex absolute inset-0 items-center bg-opacity-40 bg-black">
                            <div className="flex flex-col items-start text-white p-4 ml-6 sm:ml-10 md:ml-24 lg:ml-32 xl:ml-48 w-4/5 sm:w-3/4 md:w-3/5 lg:w-1/2 xl:w-2/5 2xl:w-1/3 flex-wrap mt-2">
                                <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-semibold mb-4" style={{ fontFamily: 'Roboto' }}>{title}</h1>
                                <h2 className="text-sm sm:text-md md:text-lg lg:text-xl mb-4" style={{ fontFamily: 'Arial' }}>{desc}</h2>
                                <button 
                                    style={{ background:'#D89F6D', color:'#003366' }} 
                                    onClick={onButtonClick} 
                                    className="w-40 px-2 py-2 text-sm md:text-md lg:text-lg bg-yellow-300 rounded shadow mt-4"
                                >
                                    {buttonText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Slide>
    );
}

export default LazySlide;
