import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import pickBy from 'lodash/pickBy'

const cleanup = (data) =>
    pickBy(data, (o) =>
        isObject(o)
            ? Object.keys(o).length > 0
            : Array.isArray(o)
            ? o.length > 0
            : isNumber(o) || (isBoolean(o) && o === true) || !isEmpty(o)
    )

export default cleanup
