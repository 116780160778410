import {
    CarouselProvider,
    Slider,
    WithStore,
    DotGroup,
} from 'pure-react-carousel';
import React, { useState, useEffect } from 'react';
import { CarouselContext } from 'pure-react-carousel';
import { makeStyles } from '@material-ui/core/styles';

import 'pure-react-carousel/dist/react-carousel.es.css';

import LazySlide from './LazySlide';
import { NextButton, PrevButton } from './Buttons';

const MySlider = React.forwardRef(({ slides, height, onSlideChange }, ref) => {
    const carouselContext = React.useContext(CarouselContext);
    const currentSlide = carouselContext.state.currentSlide;

    useEffect(() => {
        if (onSlideChange) {
            onSlideChange(currentSlide);
        }
    }, [currentSlide]);

    return (
        <Slider ref={ref}>
            {slides.map((slide, index) => (
                <LazySlide
                    key={index}
                    index={index}
                    src={slide.image}
                    title={slide.title}
                    desc={slide.desc}
                    buttonText={slide.buttonText}
                    onButtonClick={slide.onButtonClick}
                    current={currentSlide}
                    height={height}
                    currentSlideIndex={currentSlide} // pass current slide index here
                />
            ))}
        </Slider>
    );
});

const MyLazySlider = WithStore(
    MySlider,
    (state) => ({
        currentSlide: state.currentSlide,
    }),
    (state) => ({
        currentSlide: (state.carouselStore && state.carouselStore.state.currentSlide) || 0,
    })
);

const FullWidthCarousel = ({ slides, height, buttons, onSlideChange, showDots, ...props }) => {
    const [isDesktopView, setIsDesktopView] = useState(false);
    const [focusKey, setFocusKey] = useState(0);  // Add this line
    const useStyles = makeStyles(() => ({
        carouselProvider: {
          width: '100%',
          height: '100vh', // Adjust if you want a different height
        },
        slider: {
          position: 'relative',
          width: '100%',
          height: '100%',
        },
        slide: {
          width: '100%',
          height: '100%',
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        },
      }));
    const classes = useStyles();

    useEffect(() => {
        const handleResize = () => {
            setIsDesktopView(window.innerWidth > 640);
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // call the function initially to set the state based on initial window size

        return () => window.removeEventListener('resize', handleResize); // cleanup function
    }, []);

    useEffect(() => {
        const handleFocus = () => {
            setFocusKey(focusKey => focusKey + 1);  // Increment the key when the window regains focus
        };

        window.addEventListener('focus', handleFocus);

        return () => window.removeEventListener('focus', handleFocus);
    }, []);

    return (
        <div className="relative" key={focusKey}>  
            <CarouselProvider
                naturalSlideWidth={260}
                naturalSlideHeight={100}
                totalSlides={slides.length}
                dragEnabled
                touchEnabled
                infinite
                {...props}
            >
                <MyLazySlider slides={slides} height={height} onSlideChange={onSlideChange} />
                {showDots && <DotGroup className="hidden md:flex absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4" />}
                {/* {isDesktopView && ( */}
                {(

                    <>
                        <PrevButton/>
                        <NextButton/>
                    </>
                )}

            </CarouselProvider>
        </div>
    );
};


export default FullWidthCarousel;
