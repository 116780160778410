import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import { ButtonNext, ButtonBack } from 'pure-react-carousel'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    btn: {
        position: 'absolute',
        top: '45%',
        color: '#000',
        backgroundColor: '#fff',
        zIndex: 2000,
        '&:hover': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
        },
    },
    next: {
        right: theme.spacing(1),
    },
    prev: {
        left: theme.spacing(1),
    },
}))

export const PrevButton = ({ small }) => {
    const classes = useStyles()
    return (
        <IconButton
            component={ButtonBack}
            className={clsx(classes.btn, classes.prev)}
            size={small ? 'small' : 'medium'}
        >
            <ChevronLeftRoundedIcon />
        </IconButton>
    )
}

export const NextButton = ({ small }) => {
    const classes = useStyles()
    return (
        <IconButton
            component={ButtonNext}
            className={clsx(classes.btn, classes.next)}
            size={small ? 'small' : 'medium'}
        >
            <ChevronRightRoundedIcon />
        </IconButton>
    )
}
