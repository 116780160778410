import { useEffect, useState } from 'react';
import { Img } from 'react-image';

const FALLBACK = '/images/status/no-image-placeholder.webp';

const Image = ({ src, alt = '', ...props }) => {
    const srcList = [src, FALLBACK];

    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    if (!hasMounted) {
        return "loading...";
    }

    return (
        <Img
            loader="loading..."
            unloader="error!"
            src={srcList}
            alt={alt}
            loading="lazy"
            {...props}
        />
    );
};

export default Image;
